import { type EventCallbackWithError, type WidgetOptions } from '@okta/okta-signin-widget';
import { RefObject, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ExecutionEnvironment from '@docusaurus/ExecutionEnvironment';
import type IOktaSignIn from '@okta/okta-signin-widget';
import OktaAuth from '@okta/okta-auth-js';
import { useLocalStorage } from './local-storage';

export function useAuthToken() {
  return useLocalStorage<string>('lusid-support-auth-token')[0];
}

export function useAuth(
  clientId: string,
  domain: string | undefined,
  signInDialogRef: RefObject<HTMLDialogElement>,
): [
    string,
    boolean,
    boolean,
    () => Promise<void>,
    () => Promise<void>,
    () => Promise<void>,
  ] {
  const [issuerUrl, setIssuerUrl] = useState('');
  const [idps, setIDPs] = useState<WidgetOptions['idps']>([]);
  const [isLoading, setIsLoading] = useState(true);
  const setAuthToken = useLocalStorage<string | undefined>('lusid-support-auth-token')[1];
  const [authError, setAuthError] = useState('');
  const [widget, setWidget] = useState<IOktaSignIn | null>(null);
  useEffect(() => {
    if(!issuerUrl || !ExecutionEnvironment.canUseDOM) {
      setWidget(null);
      return;
    }
    (async () => {
      const OktaSignIn = (await import('@okta/okta-signin-widget')).OktaSignIn;
      const okta = new OktaAuth({
        issuer: issuerUrl,
        clientId,
        tokenManager: {
          storageKey: `okta-token-storage-${domain}`,
        },
      });
      const widget = new OktaSignIn({
        clientId,
        el: signInDialogRef.current as unknown as string,
        idpDisplay: 'PRIMARY',
        idps,
        issuer: issuerUrl,
        redirectUri: window.location.origin,
        useClassicEngine: true,
        authClient: okta,
      });
      okta.tokenManager.on('added', (_key, token) => {
        if('accessToken' in token) {
          setAuthToken(token.accessToken);
        }
      });
      okta.tokenManager.on('removed', (_key, token) => {
        if('accessToken' in token) {
          setAuthToken('');
        }
      });
      widget.on('afterError', ((_context, err) => console.error(err)) as EventCallbackWithError);
      okta.isAuthenticated().then(() => {
        setAuthToken(okta.getAccessToken());
        setIsLoading(false);
      });
      okta.start();
      setWidget(widget);
    })();
  }, [clientId, idps, issuerUrl, signInDialogRef]);
  const login = useCallback(async () => {
    setAuthToken('');
    if(!widget) {
      return;
    }
    try {
      const onModalClose = () => widget.remove();
      signInDialogRef.current?.showModal();
      signInDialogRef.current?.addEventListener('close', onModalClose, { once: true });
      const authResult = await widget.showSignInToGetTokens();
      signInDialogRef.current?.close();
      widget.authClient.tokenManager.setTokens(authResult);
    } catch (err) {
      setAuthToken('');
    }
  }, [widget, setAuthToken]);
  const logoutWithoutRedirect = useCallback(async () => {
    if(!widget) {
      return;
    }
    await widget.authClient.revokeAccessToken();
  }, [widget]);
  const refreshIssuerUrl = useCallback(async () => {
    setIsLoading(true);
    const requestDomain = domain;
    try {
      const res = await axios.get(`https://${requestDomain}.lusid.com/identity/api/authentication/information`);
      setIssuerUrl(res.data.issuerUrl);
      if(res.data.samlIdentityProviderId) {
        setIDPs([{
          text: 'Use your company credentials',
          id: res.data.samlIdentityProviderId,
        }]);
      }
    } catch (err) {
      console.error(err);
      if(requestDomain !== domain) {
        return;
      }
      setIsLoading(false);
      setIssuerUrl('');
      setAuthError(`Failed to fetch authentication information from ${requestDomain}.lusid.com`);
    }
  }, [domain]);
  useEffect(() => {
    setAuthError('');
    if(!domain) {
      setIsLoading(false);
      setIssuerUrl('');
      return;
    }
    refreshIssuerUrl();
  }, [domain]);
  return [
    authError,
    isLoading,
    !!widget,
    login,
    logoutWithoutRedirect,
    refreshIssuerUrl,
  ];
};
